.paginaDica {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    position: fixed;
    overflow: auto;
    padding: 90px 20px 30px 20px !important;
}

.cardDica {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 440px !important;
}

.cardDica .link {
    text-align: center;
    margin-top: 10px;
}

.cardDica .button {
    margin-top: 20px;
    width: 100%;
}
.campoDica{
    height: 100px !important;
}
.assinaturaZa9{
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;   
}

.cardDica p{
    font-size: 0.9em;
    margin-bottom: 20px;
    line-height: 150%;
}

.cardDica textarea:focus{
    border-color: #CED500;
}

.tituloDica{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.inputWarning{
    border: 2px solid #FE5F55 !important;
}

.assinaturaZa9:hover img{
    opacity: 1;
    transform: scale(1.2);
}
.assinaturaZa9 small{
    color: rgba(0,0,0,0.3);
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 10px;
    
}
.assinaturaZa9 img{
    width: 40px;
    opacity: 0.3;
    transition: all linear 160ms;
    
}