@import 'https://cdn.jsdelivr.net/npm/boxicons@2.0.4/css/boxicons.min.css';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none !important;
}

html,
body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background-color: #383150 !important;
}

.pagina {
    padding-top: 80px;
}

/* Custom Scroll */
::-webkit-scrollbar {
    width: 8px;
    /* display: none !important; */
}

::-webkit-scrollbar-track {
    background: #2b253d;
}

::-webkit-scrollbar-thumb {
    background: #403963;
    border-radius: 4px;
    cursor: pointer !important;
}

::-webkit-scrollbar-thumb:hover {
    opacity: 1;
    background: #CED500;
    cursor: pointer !important;
    
}

.card {
    background: #FFFFFF;
    box-shadow: 0 10px 18px 0 rgba(43, 36, 63, 0.651);
    border-radius: 18px;
    padding: 16px;
}

.success {
    background-color: #9BE564 !important;
}

.warning {
    background-color: #FFF275 !important;
}

.error,
.danger {
    background-color: #FE5F55 !important;
}

.info {
    background-color: #4DCDC6 !important;
}

.default {
    background-color: #efecf2 !important;
}

.pink {
    background-color: #EAB3D5 !important;
}

.purple {
    background-color: #c77dff !important;
}

.msg {
    padding: 16px;
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.8);
}

.label {
    font-size: 14px;
    margin-top: 5px
}

input {
    display: inline-block;
    border-radius: 6px !important;
}

input:focus {
    border-color: #4DCDC6 !important;
}

.is-text {
    font-size: 14px;
    color: #3831507e !important;
}

.is-text:hover {
    color: #383150 !important;
}
.card{
    border-radius: 8px !important;
}


::-webkit-input-placeholder {
    color: #D1B9E3;
 }
  
 :-moz-placeholder { /* Firefox 18- */
    color: #D1B9E3;  
 }
  
 ::-moz-placeholder {  /* Firefox 19+ */
    color: #D1B9E3;  
 }
  
 :-ms-input-placeholder {  
    color: #D1B9E3;  
 }